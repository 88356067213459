import React from "react";
import NavBar from "../Reuseable/NavBar/NavBar";
import ServicesHeader from "./ServicesHeader";
import OurSevices from '../Reuseable/OurSevices/OurSevices';
import Footer from "../Reuseable/Footer/Footer";

function Services() {
    return(
        <div>
            <NavBar />
            <ServicesHeader />
            <OurSevices />
            <Footer />
        </div>
    )
}

export default Services;