import React, { Component } from "react";

class AboutBio extends Component {
  render() {
    return (
      <div className="container mt-5">
        <div className="row">

          <div className="col-md-6  text-center">
            <div className=" shadow-lg p-3 mb-5 bg-white rounded">
              <h3 className="header" style={{color: "#111"}}><b>ABOUT US</b></h3>
              <p style={{fontFamily: "Poppins", fontSize: "18px" }}>
              Christmodex Limited is a professional registered Geomatics company specializing in cadastral, Hydrography,
                                topographic, engineering, Aerial and LIDAR survey projects using tradition techniques and
                                modern technologies to produce well-presented and accurate data. Christmodex Limited consists
                                of well experienced team. Our team has the technical expertise, equipment and the technology to
                                deliver the best solutions to our clients, and to our clients' our company is known to be reliable, delivers projects
                                on time and within an agreed budget, and adheres to the highest ethical industry standards.              </p>
            </div>
          </div>

          <div className="col-md-6 text-center shadow-lg p-3 mb-5 bg-white rounded">
            {/* <div className="offset-1"> */}
              <h3 className="header" style={{color: "#111"}}><b>OUR VISION</b></h3>
              <p style={{fontFamily: "Poppins", fontSize: "18px" }}>
                To be the world's touchstone in the provision of land survey, Engineering and Construction Services for its work method, technology and human resource competencies.
              </p>

              <div className="mt-5">
                <h3 className="header" style={{color: "#111"}}><b>OUR MISSION</b></h3>
              <p style={{fontFamily: "Poppins", fontSize: "18px" }}>
                Christmodex Limited strives to provide our stakeholders and clients with value and quality of services, through the provision of survey services, enginering services, procurement, construction, operation and management of infrastructure by pushing to exceed expectation from our clients, community and our professional society on the levels of honesty, fairness and timeliness.
                </p>
              {/* </div> */}

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default AboutBio;
