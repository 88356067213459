import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import VolumeCalculation from "./components/Pages/VolumeCalculation";
import AreaCalculation from "./components/Pages/AreaCalculation";
import PrecisePositioning from "./components/Pages/PrecisePositioning";
import VolumeChecking from "./components/Pages/VolumeChecking";
import DimensionalControl from "./components/Pages/DimensionalControl";
import AsBuiltRecords from "./components/Pages/AsBuiltRecords";


function App() {
  return (
    <div>
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/volumecalculation" component={VolumeCalculation} />
        <Route exact path="/areacalculation" component={AreaCalculation} />
        <Route exact path="/precisepositioning" component={PrecisePositioning} />
        <Route exact path="/volumechecking" component={VolumeChecking} />
        <Route exact path="/dimensionalcontrol" component={DimensionalControl} />
        <Route exact path="/asbuiltrecords" component={AsBuiltRecords} />
      </Router>
    </div>
  );
}

export default App;
