import React, { Component } from "react";
import "./style.css";
import {Link} from "react-router-dom";
import Image1 from "./Image/background1.jpg";

class PrecisePositioningHeader extends Component {
  render() {
    return (
      <div className="card bg-dark text-white">
      <img src={Image1} className="card-img" alt="..." style={{height: "350px"}}/>
      <div className="card-img-overlay"   style={{ marginTop: 120 + "px" }}>
        <h1 className="card-title text-center header">PRECISE POSITIONING</h1>
        <Link className="btn btn-outline-light btn-sm mx-3 mt-5" to="/"><b>Home</b></Link>
      </div>
    </div>
    );
  }
}

export default PrecisePositioningHeader;
