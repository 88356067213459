import React, { Component } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (


      <footer className="footer-area section-gap" style={{ backgroundColor: "#444444", paddingTop: "80px", paddingBottom: "80px" }}>
        <div className="container">
          <div className="row">
          
            <div className="col-lg-3 col-md-6 col-sm-6">
            <h5 style={{color:"#C4C4C4"}}>Company</h5>
              <ul>
                <li style={{ paddingLeft: 10, paddingBottom: 10, fontSize:15, color:"#FFFFFF"}} > <Link to="/" style={{color:"#FFFFFF"}}>Home</Link></li>
                <li style={{ paddingLeft: 10, paddingBottom: 10, fontSize: 15, color:"#FFFFFF"}} > <Link to="/AboutUs" style={{color:"#FFFFFF"}}>About us</Link></li>
                <li style={{ paddingLeft: 10, paddingBottom: 10, fontSize: 15 ,color:"#FFFFFF"}} > <Link to="/Services"style={{color:"#FFFFFF"}}>Our service</Link></li>
                <li style={{ paddingLeft: 10, paddingBottom: 10, fontSize: 15,color:"#FFFFFF"}} > <Link to="/ContactUs"style={{color:"#FFFFFF"}}>Contact us</Link></li>
              </ul>
            </div>
       
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h5 style={{color:"#C4C4C4"}}>Contact us</h5>
                <p className="box" style={{ color: "color: #777777" }}>
                  <span style={{fontWeight:"bold", color:"#FFFFFF", paddingRight:10}}>Address 1:</span><span style={{ color:"#FFFFFF", }} >1st Floor, (LINDEV.PLAZA), 16 Amodu Ojikutu Street, Off Bishop Oluwole Street, Victoria Island, lagos.
</span>
								</p>
                <p className="box" style={{ color: "color: #777777" }}>
                  <span style={{fontWeight:"bold", color:"#FFFFFF", paddingRight:10}}>Address 2:</span><span style={{ color:"#FFFFFF", }} >67B Nza Street, By admiral Hotel Junction Independence Layout Enugu, Enugu State.
</span>
								</p>
                <p className="box" style={{ color: "color: #777777" }}>
                  <span style={{fontWeight:"bold", color:"#FFFFFF", paddingRight:10}}>Phone :</span><span style={{fontFamily:"Regular", color:"#FFFFFF"}}>07039202182, 08090794208
</span>
								</p>
                <p className="box" style={{ color: "color: #777777" }}>
                  <span style={{fontWeight:"bold", color:"#FFFFFF", paddingRight:10}}>Email :</span><span style={{fontFamily:"Regular", color:"#FFFFFF"}}>christmodex@gmail.com
</span>
								</p>
                
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h5 style={{color:"#C4C4C4"}}>Newsletter</h5>
                <p className="box">Stay update with our latest</p>
                <div className="" id="mc_embed_signup">
                  <form className="form-inline">
                    <input className="form-control" name="EMAIL" placeholder="Enter Email" type="email" />
                    <button className="click-btn btn " style={{backgroundColor:"#DA8E2B"}}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <div style={{ position: "absolute; left: -5000px" }}>
                    </div>
                    <div className="info"></div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 social-widget">
              <div className="single-footer-widget">
                <h5 style={{color:"#C4C4C4"}} className="">Follow Us</h5>
                <p className="box">Let us be social</p>
                <div className="footer-social d-flex align-items-center">
                  <Link to="#"><i className="fa fa-facebook" style={{color:"#DA8E2B"}}></i></Link>
                  <Link to="#"><i className="fa fa-twitter" style={{color:"#DA8E2B"}}></i></Link>
                  <Link to="#"><i className="fa fa-dribbble" style={{color:"#DA8E2B"}}></i></Link>
                  <Link to="#"><i className="fa fa-behance" style={{color:"#DA8E2B"}}></i></Link>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row " >
            <div className="col-lg-12 "><p className="footer-text box" style={{textAlign:"center"}}>
                  Copyright &copy; 2020 All rights reserved <i className="fa fa-heart-o" style={{ color: "#2d82ec" }} aria-hidden="true"></i>
                </p>
                </div>
                </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
