import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import VPrecisePositioningHeader from "./PrecisePositioningHeader";
import PrecisePositioningContent from "./PrecisePositioningContent";
import Footer from "../../Reuseable/Footer/Footer";

function PrecisePositioning() {
    return(
        <div>
            <NavBar />
            <VPrecisePositioningHeader />
            <PrecisePositioningContent />
            <Footer />
        </div>
    )
}

export default PrecisePositioning;