import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import VolumeCalculationHeader from "./VolumeCalculationHeader";
import VolumeMainContent from "./VolumeMainContent";
import Footer from "../../Reuseable/Footer/Footer";

function VolumeCalculation() {
    return(
        <div>
            <NavBar />
            <VolumeCalculationHeader />
            <VolumeMainContent />
            <Footer />
        </div>
    )
}

export default VolumeCalculation;