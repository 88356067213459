import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import AreaCalculationHeader from "./AreaCalculationHeader";
import AreaCalculationContent from "./AreaCalculationContent";
import Footer from "../../Reuseable/Footer/Footer";

function VolumeCalculation() {
    return(
        <div>
            <NavBar />
            <AreaCalculationHeader />
            <AreaCalculationContent />
            <Footer />
        </div>
    )
}

export default VolumeCalculation;