import React, { Component } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import Image from "./../../../images/logo.png";
import Image1 from "./../../../images/logo_2.png";

class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{background:"#444444"}} >
        <div className="container">
          <Link className="navbar-brand mx-1" to="#">
           <img
              src={Image}
              alt="logo"
              style={{ height: "80px",  }}
            />
            <img
              src={Image1}
              alt="logo"
              style={{ height: "50px",  }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AboutUs">
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ContactUs">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
