import React, { Component } from "react";
import "./MainContent.css";
import { Link } from "react-router-dom";
import Image1 from "./Image/modex.png";
import Image2 from "./Image/modex1.png";
import Image3 from "./Image/modex2.png";
import Image4 from "./Image/modex3.png";
import Image5 from "./Image/modex4.png";
import Image6 from "./Image/modex5.png";

class MainContent extends Component {
  render() {
    return (
      <div id="demo" className="carousel slide" data-ride="carousel">
        {/* <!-- Indicators --> */}
        <ul className="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" className="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
          <li data-target="#demo" data-slide-to="3"></li>
          <li data-target="#demo" data-slide-to="4"></li>
          <li data-target="#demo" data-slide-to="5"></li>
        </ul>

        {/* <!-- The slideshow --> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={Image1}
              alt="Los Angeles"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-right"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>CADASTRIAL SURVEY</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>Land registration and Dispute survey, </h5>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}> Acquisition and Disposition of land.</h5>

              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/Services" style={{animationDelay: "3s"}}>
                  <b>Learn More</b>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image2}
              alt="Chicago"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-center"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>ENGINEERING SURVEY</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>Topographic Survey,Volume </h5>
              <h5 className="animated zoomIn" style={{animationDelay: "3s"}}> calculation, Area calculation, etc.</h5>
              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/Services" style={{animationDelay: "3s"}}>
                  <b>Learn more</b>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image3}
              alt="New York"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-right"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>SITE ENGINEERING SURVEY</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>From conception, planning, design, construction, </h5>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>maintenance, and operation of engineered projects </h5>
              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/Services" style={{animationDelay: "2s"}}>
                  <b>Learn more</b>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image4}
              alt="Chicago"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-left"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>NETWORK GNSS SURVEYS</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>GPS Control Survey and Harmonization</h5>
              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/Services" style={{animationDelay: "3s"}}>
                  <b>Learn more</b>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image4}
              alt="New York"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-left"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>HYDROGRAPHC SURVEY</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>Breakwater Harbour and channel </h5>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>bathymetry, volume computation for </h5>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>dredging and reclamation</h5>
              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/Services" style={{animationDelay: "3s"}}>
                  <b>Learn more</b>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image6}
              alt="New York"
              className="d-block w-100"
            />
            <div
              className="carousel-caption text-right"
            >
              <h1 className="animated zoomIn" style={{animationDelay: "1s"}}>Christ Modex Limited</h1>
              <h5 className="animated zoomIn" style={{animationDelay: "2s"}}>Think us</h5>
              <h5 className="animated zoomIn" style={{animationDelay: "3s"}}>Think Diference</h5>
              
              <Link className="btn btn-outline-light btn-sm animated zoomIn" to="/contactus" style={{animationDelay: "4s"}}>
                  <b>Contact us</b>
              </Link>
            </div>
          </div>
        </div>

        {/* <!-- Left and right controls --> */}
        <a className="carousel-control-prev" href="#demo" data-slide="prev">
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next">
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    );
  }
}

export default MainContent;
