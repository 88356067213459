import React, {Component} from "react";
import {Link} from "react-router-dom";

class AboutText extends Component{
    render() {
        return(
            <div className="container mt-4 text-center">
                <div className="col-md-12 col-sm-6">
                  <h4 className="text-uppercase font-30" style={{color: "#111", fontFamily: "Poppins", fontSize: "20px"}}><b>Think Us, Think Different<br/></b></h4>
                <Link className="btn btn-outline-primary radius-xl mt-2 mb-4" to="/ContactUs" style={{borderRadius: "5px"}}><b>Start New Projects</b></Link>  
                </div>
            </div>
        )
    }
}

export default AboutText;