import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import VolumeCheckingHeader from "./VolumeCheckingHeader";
import VolumeCheckingContent from "./VolumeMainContent";
import Footer from "../../Reuseable/Footer/Footer";

function VolumeChecking() {
    return(
        <div>
            <NavBar />
            <VolumeCheckingHeader />
            <VolumeCheckingContent />
            <Footer />
        </div>
    )
}

export default VolumeChecking;