import React, { Component } from "react";
import Image1 from "../../../images/Engineeringsurvey.png";
import Image2 from "../../../images/NETWORK.png";
import Image3 from "../../../images/HYDROGRAPHIC.png";
import Image4 from "../../../images/MAPPING.png";
import Image5 from "../../../images/CADASTRAL.png";

class About extends Component {
    render() {
        return (
            <div className="container-fluid mt-5">

                <div className="row mt-5" style={{paddingRight:30, paddingLeft:40}}>
                    {/* <div className="col col-md-3 mb-3 text-center">
                    <h3 ><b>ABOUT US</b></h3>
                </div> */}
                    <div className="col-md-12"></div>
                    <div className="col-sm-4 col-md-8 mt-2 " style={{ padding: 40 }}>
                        <div className="col-sm-12 col-md-12">
                            <h2 ><b className="text-left d-flex justify-content-left">ABOUT US</b></h2>
                        </div>
                        <div className="col-sm-12 col-md-12" style={{ textAlign: "justify", padding: 20 }}>

                            {/* <p style={{textAlign: "justify"}}>
                        <strong>
                            <strong style={{fontSize: "16px", fontFamily: "Roboto,  sans-serif"}}> */}
                            <p className="text-justify d-flex justify-content-center" style={{ fontFamily: "Poppins", fontSize: "18px", lineHeight: 2 }}>
                                Christmodex Limited is a professional registered Geomatics company specializing in cadastral, Hydrography,
                                topographic, engineering, Aerial and LIDAR survey projects using tradition techniques and
                                modern technologies to produce well-presented and accurate data. Christmodex Limited consists
                                of well experienced team. Our team has the technical expertise, equipment and the technology to
                                deliver the best solutions to our clients, and to our clients' our company is known to be reliable, delivers projects
                                on time and within an agreed budget, and adheres to the highest ethical industry standards.
                            </p>
                            {/* </strong>
                        </strong>
                    </p> */}
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-3 mt-2 " style={{ padding: 40 }}>
                        <div className="col-sm-12 col-md-12">
                            <h2 className="text-left d-flex justify-content-left"><b>OUR SERVICES</b></h2>

                        </div>
                        <div className="col-sm-12 col-md-12" style={{ textAlign: "justify", padding: 20 }}>
                            <br />
                            <ul class="fa-ul text-justify">
                                <li style={{ paddingLeft: 20, paddingBottom: 20, fontSize: 20 }} > <span class="fa-li"><img src={Image1} width="100%"></img></span>Engineering Survey</li>
                                <li style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 10, fontSize: 20 }}> <span class="fa-li"><img src={Image2} width="100%"></img></span>Network GNSS Survey</li>
                                <li style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 10, fontSize: 20 }}> <span class="fa-li"><img src={Image3} width="100%"></img></span>Hydrographic Survey</li>
                                <li style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 10, fontSize: 20 }}> <span class="fa-li"><img src={Image4} width="100%"></img></span>Mapping</li>
                                <li style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 10, fontSize: 20 }}> <span class="fa-li"><img src={Image5} width="100%"></img></span>Cadastral Survey</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;