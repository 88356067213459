import React from "react";
import NavBar from "../Reuseable/NavBar/NavBar";
import MainContent from "./MainContent/MainContent";
import About from './About/About';
import OurSevices from '../Reuseable/OurSevices/OurSevices';
import ProjectPage from './ProjectPage/ProjectPage';
import Footer from "../Reuseable/Footer/Footer";

function Home() {
  return (
    <div>
      <NavBar />
      <MainContent />
      <About />
      <OurSevices />
      {/* <ProjectPage /> */}
      <Footer />
    </div>
  );
}

export default Home;
