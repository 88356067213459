import React, { Component } from "react";
import { Link } from "react-router-dom";
import Image1 from "../../../images/001.png";
import Image2 from "../../../images/002.png";
import Image3 from "../../../images/003.png";
import Image4 from "../../../images/004.png";
import Image5 from "../../../images/005.png";
import Image6 from "../../../images/006.png";

class OurSevices extends Component {
  render() {
    return (
      <div className="container-fluid mt-5 mb-5">
        <div>
          

          <div className="container mt-4">
            <div className="text-center mb-4 border-top border-bottom" >
              <span style={{fontSize: "50px",fontWeight:'bold', fontStyle:"SemiBold", fontFamily: "Prompt"}}>ENGINEERING SURVEY</span>
            </div>

            <div className="card-deck">
              <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image1} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>VOLUME CALCULATION</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>Volume anaysis is one the services we offer ...</h4>
                  <Link to="/VolumeCalculation" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>

              


              <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image3} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>AREA CALCULATION</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>Area calculation is one the services we offer ...</h4>
                  <Link to="/areacalculation" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>
              <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image2} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>PRECISE POSITIONING</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>Precise positioning is one the services we offer ...</h4>
                  <Link to="/precisepositioning" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>

            </div>
          </div>

          <div className="container mt-5">
            <div className="card-deck">

            <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image4} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>CONSTRUCTION SETTING-OUT</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>construction setting-out is one the services we offer ...</h4>
                  <Link to="/asbuiltrecords" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>

              


              <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image5} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>DIMENSIONAL CONTROL</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>dimensional control is one the services we offer ...</h4>
                  <Link to="/dimensionalcontrol" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>
              <div
                className=" mx-4 shadow-lg"
                width="100%" height="100%"
              >
                <img className="card-img-top" style={{ height: 240+"px"}} src={Image6} alt="..." />
                <div
                  className="text-center"
                  style={{ backgroundColor: "#444444",height: 110+"px" }}
                >
                  <h4 className="text-white" style={{paddingTop:10, fontSize: "22px", fontStyle:"SemiBold",  fontFamily: "Prompt"}}>SITE ENGINEERING SURVEY</h4>
                  <h4 className="text-white" style={{ fontSize: "12px", fontStyle:"Regular",  fontFamily: "Prompt"}}>we offer overall site engineering survey</h4>
                  <Link to="/precisepositioning" className="btn " style={{backgroundColor:"#DA8E2B", borderColor:"#DA8E2B", color:"#FFFFFF"}}>
                    <b>Learn More</b>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurSevices;
