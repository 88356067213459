import React from "react";
import NavBar from "../Reuseable/NavBar/NavBar";
import ContactHeader from "./ContactHeader";
import ContactView from "./ContactView";
import Footer from "../Reuseable/Footer/Footer";

function ContactUs() {
    return(
        <div>
            <NavBar />
            <ContactHeader />
            <ContactView />
            <Footer />
        </div>
    )
}

export default ContactUs;