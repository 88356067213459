import React, {Component} from "react";

class PrecisePositioningContent extends Component {
    render() {
        return(
            <div className="container mt-5 border-top border-bottom">
                <div className="container mt-4">
                <h1 style={{color: "#111", fontSize: "25px", fontFamily: "Roboto,  sans-serif"}}><b>PRECISE POSITIONING</b></h1>
                <h5 className="mt-4 mx-4 mb-3" style={{color: "#111", fontSize: "18px", fontFamily: "Roboto,  sans-serif"}}><b>FOUNDATION/CIVIL WORKS AND BUILDING SERVICES</b></h5>
                <ul style={{fontSize: "17px"}}>
                    <li>Piling works (pre-cast and in-situ)</li>
                    <li>Raft foundation (cellular/basements)</li>
                    <li>Shore line protection (concrete/steel)</li>
                    <li>Coffer dam construction</li>
                    <li>Earth stabilisation/improvement</li>
                    <li>Bond wall and Duck bank construction</li>
                </ul>
                <h5 className="mx-4 mb-3" style={{color: "#111", fontSize: "18px", fontFamily: "Roboto,  sans-serif"}}><b>CIVIL WORKS AND BUILDING SERVICES</b></h5>
                <ul style={{fontSize: "17px"}}>
                    <li>Site preparation</li>
                    <li>Built in reinforced concrete and structural steel</li>
                    <li>Construction of cellar pit and derrick floor</li>
                    <li>Flexible and rigid pavement construction</li>
                    <li>Dredging</li>
                    <li>Building services including water/sewerage treatment plants</li>
                    <li>Pipeline lay and construction</li>
                    <li>Construction of flow lines</li>
                </ul>
                </div>
            </div>
        )
    }
}

export default PrecisePositioningContent;