import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Image1 from "./Image/background1.jpg";

class ContactView extends Component {
  render() {
    return (
      // <div className="card text-white">
      //   <img
      //     src={Image1}
      //     className="card-img"
      //     alt="..."
      //     style={{ height: "100vh" }}
      //   />
      //   <div className="card-img-overlay">
      //     <div className="container" style={{ marginTop: 70 + "px" }}>
      //       <div className="row">

      //         <div className="col-md-3 col-sm-3" style={{fontSize: "16px", fontFamily: "Roboto,  sans-serif"}}>
      //           <div
      //             className="card float-left mr-2"
      //             style={{
      //               width: "340px",
      //               borderRadius: "15px",
      //               backgroundColor: "rgba(255, 255, 255, 0.2)"
      //             }}
      //           >
      //             <div className="card-body">
      //               <h5 className="text-center" style={{ color: "#fff", fontSize: "18px", fontFamily: "Roboto,  sans-serif" }}>
      //                 <b>Lagos Office:</b>
      //               </h5>
      //               <p  style={{fontSize: "14px", fontFamily: "Roboto,  sans-serif"}}>
      //                 1st Floor, (LINDEV.PLAZA), 16 Amodu Ojikutu Street,
      //                 Off Bishop Oluwole Street, Victoria Island, lagos.
      //               </p>

      //               <h5 className="text-center" style={{fontSize: "18px", fontFamily: "Roboto,  sans-serif"}}>
      //                 <b>Enugu Office:</b>
      //               </h5>
      //               <p style={{fontSize: "14px", fontFamily: "Roboto,  sans-serif"}}>
      //                 67B Nza Street, By Admiral Hotel Juntion<br />
      //                 Independence Layout Enugu, Enugu State
      //               </p>
      //             </div>
      //           </div>

      //           <div
      //             className="card float-left mr-2 mt-4"
      //             style={{
      //               width: "340px",
      //               borderRadius: "15px",
      //               backgroundColor: "rgba(255, 255, 255, 0.2)"
      //             }}
      //           >
      //             <div className="card-body text-center">
      //               <h5 className="" style={{ color: "#fff", fontSize: "18px", fontFamily: "Roboto,  sans-serif" }}>
      //                 <b>E-mail</b>
      //               </h5>
      //               <p style={{fontSize: "14px", fontFamily: "Roboto,  sans-serif"}}>christmodex@gmail.com</p>
      //               <p style={{fontSize: "14px", fontFamily: "Roboto,  sans-serif"}}>www.christmodex.com</p>
      //             </div>
      //           </div>

      //           <div
      //             className="card float-left mr-2 mt-4"
      //             style={{
      //               width: "340px",
      //               borderRadius: "15px",
      //               backgroundColor: "rgba(255, 255, 255, 0.2)"
      //             }}
      //           >
      //             <div className="card-body text-center">
      //               <h5 style={{ color: "#fff", fontSize: "18px", fontFamily: "Roboto,  sans-serif" }}>
      //                 <b> Phone Numbers</b>
      //               </h5>
      //               <p style={{fontSize: "14px", fontFamily: "Roboto,  sans-serif"}}>07039202182, 08090794208</p>
      //             </div>
      //           </div>
      //         </div>
              
      //         <div
      //           className="card float-right offset-lg-2 col-md-7 col-sm-4"
      //           style={{ width: "700px", borderRadius: "15px" }}
      //         >
      //           <div className="card-body">
      //             <h2
      //               className="card-title text-center"
      //               style={{ color: "#111", fontSize: "20px", fontFamily: "Roboto,  sans-serif" }}
      //             >
      //               <b>Let's Convert Your Idea into Reality</b>
      //             </h2>
      //             <p className="card-text text-dark text-center" style={{color: "#111", fontSize: "15px", fontFamily: "Roboto,  sans-serif" }}>
      //               Send us an email, call, or pay us a visit because we will be
      //               delighted to hear from you.
      //             </p>
      //             <form>
      //               <div className="form-row">
      //                 <div className="form-group col-md-6 mt-4">
      //                   <input
      //                     type="email"
      //                     className="form-control"
      //                     id="inputEmail4"
      //                     placeholder="First Name"
      //                   />
      //                 </div>
      //                 <div className="form-group col-md-6 mt-4">
      //                   <input
      //                     type="password"
      //                     className="form-control"
      //                     id="inputPassword4"
      //                     placeholder="Last Name"
      //                   />
      //                 </div>
      //               </div>
      //               <div className="form-group mt-4">
      //                 <input
      //                   type="text"
      //                   className="form-control "
      //                   id="inputAddress"
      //                   placeholder="Your Email"
      //                 />
      //               </div>
      //               <div className="form-group mt-5">
      //                 <textarea
      //                   className="form-control"
      //                   id="exampleFormControlTextarea1"
      //                   rows="3"
      //                   placeholder="Tell us your project or idea"
      //                 ></textarea>
      //               </div>
      //               <Link
      //                 to="#"
      //                 className="btn text-white btn-block mt-5"
      //                 style={{ backgroundColor: "#002eff", borderRadius: "5px"}}
      //               >
      //                 Submit
      //               </Link>
      //             </form>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      <div className="container shadow p-3 mt-5 mb-5">
        <div className="row">
        <div className="col-md-5 col-sm-4">
        <div className="card shadow-lg p-2">
          <div className="card-body">
          <h5 className="card-title" style={{ color: "#111", fontSize: "20px", fontFamily: "Roboto,  sans-serif" }}><b>Lagos Office:</b></h5>
            <p className="card-text" style={{ color: "#111", fontSize: "16px", fontFamily: "Roboto,  sans-serif" }}>1st Floor, (LINDEV.PLAZA), 16 Amodu Ojikutu Street, Off Bishop Oluwole Street, Victoria Island, lagos.</p>
            </div>
          </div>
          <div className="card mt-5 shadow-lg p-2">
          <div className="card-body">
          <h5 className="card-title" style={{ color: "#111", fontSize: "20px", fontFamily: "Roboto,  sans-serif" }}><b>Enugu Office:</b></h5>
            <p className="card-text" style={{ color: "#111", fontSize: "18px", fontFamily: "Roboto,  sans-serif" }}>67B Nza Street, By Admiral Hotel Juntion Independence Layout Enugu, Enugu State.</p>
            </div>
          </div>
          <div className="card mt-5 shadow-lg p-1">
          <div className="card-body">
          <h5 className="card-title" style={{ color: "#111", fontSize: "20px", fontFamily: "Roboto,  sans-serif" }}><b>Phone Numbers:</b></h5>
            <p className="card-text" style={{ color: "#111", fontSize: "18px", fontFamily: "Roboto,  sans-serif" }}>07039202182, 08090794208</p>
            </div>
          </div>
        </div>
          <div className="col-md-7 col-sm-4">
          <div className="card p-4">
          <h2
                className="card-title text-center"
                style={{ color: "#111", fontSize: "30px", fontFamily: "Roboto,  sans-serif" }}
              >
                <b>Let's Convert Your Idea into Reality</b>
              </h2>
              <p className="card-text text-dark text-center" style={{color: "#111", fontSize: "15px", fontFamily: "Roboto,  sans-serif" }}>
                Send us an email, call, or pay us a visit because we will be delighted to hear from you.
              </p>
          <form>
                     <div className="form-row">
                       <div className="form-group col-md-6 mt-4">
                         <input
                           type="email"
                           className="form-control"
                           id="inputEmail4"
                           placeholder="First Name"
                         />
                       </div>
                       <div className="form-group col-md-6 mt-4">
                         <input
                           type="password"
                           className="form-control"
                           id="inputPassword4"
                           placeholder="Last Name"
                         />
                       </div>
                     </div>
                     <div className="form-group mt-4">
                       <input
                         type="text"
                         className="form-control "
                         id="inputAddress"
                         placeholder="Your Email"
                       />
                     </div>
                     <div className="form-group mt-5">
                       <textarea
                         className="form-control"
                         id="exampleFormControlTextarea1"
                         rows="3"
                         placeholder="Tell us your project or idea"
                       ></textarea>
                     </div>
                     <Link
                       to="#"
                       className="btn text-white btn-block mt-5"
                       style={{ backgroundColor: "#002eff", borderRadius: "5px"}}
                     >
                       Send Message
                     </Link>
                   </form>
              </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ContactView;
