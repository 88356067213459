import React, { Component } from "react";
import {Link} from "react-router-dom";
import Image1 from "./Image/background1.jpg";

class ContactHeader extends Component {
  render() {
    return (
      <div className="card bg-dark text-white">
      <img src={Image1} className="card-img" alt="..." style={{height: "300px"}}/>
      <div className="card-img-overlay"   style={{ marginTop: 100 + "px" }}>
        <h1 className="card-title text-center header" style={{fontFamily: "merienda"}}>CONTACT US</h1>
        <Link className="btn btn-outline-light btn-sm mx-3 mt-5" to="/"><b>Home</b></Link>
      </div>
    </div>
    );
  }
}

export default ContactHeader;
