import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import DimensionalControlHeader from "./DimensionalControlHeader";
import DimensionalControlContent from "./DimensionalControlContent";
import Footer from "../../Reuseable/Footer/Footer";

function DimensionalControl() {
    return(
        <div>
            <NavBar />
            <DimensionalControlHeader />
            <DimensionalControlContent />
            <Footer />
        </div>
    )
}

export default DimensionalControl;