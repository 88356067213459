import React from "react";
import NavBar from "../../Reuseable/NavBar/NavBar";
import AsBuiltRecordsHeader from "./AsBuiltRecordsHeader";
import AsBuiltRecordsContent from "./AsBuiltRecordsContent";
import Footer from "../../Reuseable/Footer/Footer";

function AsBuiltRecords() {
    return(
        <div>
            <NavBar />
            <AsBuiltRecordsHeader />
            <AsBuiltRecordsContent />
            <Footer />
        </div>
    )
}

export default AsBuiltRecords;