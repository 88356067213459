import React from "react";
import NavBar from "../Reuseable/NavBar/NavBar";
import AboutHeader from "./AboutHeader";
// import AboutPage from "./AboutPage";
import AboutBio from "./AboutBio";
import AboutText from "./AboutText";
import Footer from "../Reuseable/Footer/Footer";


function AboutUs() {
    return(
        <div>
            <NavBar />
            <AboutHeader />
            {/* <AboutPage /> */}
            <AboutBio />
            <AboutText />
            <Footer />
        </div>
    )
}

export default AboutUs;